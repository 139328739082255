/* open-sans-condensed-300 - latin */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('./open-sans-condensed-v14-latin-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Condensed Light'), local('OpenSansCondensed-Light'),
         url('./open-sans-condensed-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./open-sans-condensed-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('./open-sans-condensed-v14-latin-300.woff') format('woff'), /* Modern Browsers */
         url('./open-sans-condensed-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./open-sans-condensed-v14-latin-300.svg#OpenSansCondensed') format('svg'); /* Legacy iOS */
  }