body {
	font-family: 'Roboto', sans-serif;
	margin: 0;
	height: calc(100vh - 10px);
	padding: 0px;
}
#root {
	height: calc(100vh - 10px);
}

/** SCROLL STYLE SECTION */
/* width */
::-webkit-scrollbar {
    height: 9px;
    width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #e7e7e7;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #d4d4d4;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #aaaaaa;
}
